import { VueConstructor } from "vue";

const petitionPaths = {
  search: {
    shiftTable: "/petition/search/shift-table",
    shift: "/petition/search/shift",
    attendance: "/petition/search/attendance",
    shiftPattern: "/petition/search/shift-pattern",
    legalHoliday: "/petition/search/legal-holiday"
  },
  insert: {
    vacation: "/petition/insert/vacation",
    overtimeWork: "/petition/insert/overtime-work",
    attendance: "/petition/insert/attendance",
    shift: "/petition/insert/shift"
  },
  closingDay: "/petition/closing-day",
  defermentDay: "/petition/deferment-day"
} as const;

const approvalPaths = {
  overtimeWork: {
    search: "approval/overtime-work/search",
    resolve: "approval/overtime-work/resolve",
    reject: "approval/overtime-work/reject",
    affiliation: "approval/overtime-work/affiliation",
    searchhome: "approval/overtime-work/home",
    cancel: "approval/overtime-work/cancel",
    update: "approval/overtime-work/update",
    breakTime: "approval/overtime-work/break-time",
    endTime: "approval/overtime-work/end-time",
    endUnixTime: "approval/overtime-work/end-unix-time"
  },
  shift: {
    search: "approval/shift/search",
    resolve: "approval/shift/resolve",
    reject: "approval/shift/reject",
    affiliation: "approval/shift/affiliation",
    searchhome: "approval/shift/home",
    cancel: "approval/shift/cancel",
    update: "approval/shift/update"
  },
  attendance: {
    search: "approval/attendance/search",
    resolve: "approval/attendance/resolve",
    reject: "approval/attendance/reject",
    affiliation: "approval/attendance/affiliation",
    searchhome: "approval/attendance/home",
    cancel: "approval/attendance/cancel",
    update: "approval/attendance/update",
    endTime: "approval/attendance/end-time",
    overBreakTime: "approval/attendance/over-break-time",
    shiftBreakTime: "approval/attendance/shift-break-time"
  },
  vacation: {
    search: "approval/vacation/search",
    resolve: "approval/vacation/resolve",
    reject: "approval/vacation/reject",
    affiliation: "approval/vacation/affiliation",
    searchhome: "approval/vacation/home",
    cancel: "approval/vacation/cancel",
    update: "approval/vacation/update"
  },
  affiliation: "approval/affiliation",
  affiliationall: "approval/affiliation/all",
  employee: "approval/employee",
  shiftPattern: "approval/shift-pattern",
  shiftTable: {
    get: "approval/shift-table",
    post: "approval/shift-table",
    closingMonth: "approval/shift-table/closing-month",
    affiliation: "approval/shift-table/affiliation",
    attendance: "approval/shift-table/attendance",
    overwork: "approval/shift-table/overwork",
    shift: "approval/shift-table/shift",
    vacation: "approval/shift-table/vacation"
  }
} as const;

const checkApproval = {
  attendance: {
    search: "check-approval/attendance/search",
    check: "check-approval/attendance/check"
  },
  overwork: {
    search: "check-approval/overwork/search",
    check: "check-approval/overwork/check"
  },
  shift: {
    search: "check-approval/shift/search",
    check: "check-approval/shift/check"
  },
  vacation: {
    search: "check-approval/vacation/search",
    check: "check-approval/vacation/check"
  }
} as const;

const alertPaths = {
  attendance: "/alert/attendance",
  overtimeWork: "/alert/overtime-work",
  approval: "/alert/approval",
  absence: "/alert/absence",
  leaveEarly: "/alert/leave-early",
  legalHoliday: "/alert/legal-holiday",
  absent: "/alert/absent"
} as const;

const userMaster = {
  default: "user-master",
  info: {
    default: "user-master/info",
    valid: "user-master/info/valid",
    invalid: "user-master/info/invalid",
    retire: "user-master/info/retire",
    unretire: "user-master/info/unretire",
    biko: "user-master/info/biko"
  },
  general: "general",
  departments: {
    default: "user-master/department",
    affiliation: "user-master/department/affiliation"
  },
  workSchedule: {
    default: "user-master/work-schedule",
    list: "user-master/work-schedule/list",
    table: "user-master/work-schedule/table",
    shift: "user-master/work-schedule/shift"
  }
} as const;

export const Paths = {
  atuhShozoku: "/auth/shozoku",
  authManager: "/auth/manager",
  authTemporary: "/auth/temporary",
  attendance: "/attendance",
  attendanceFix: "/attendance/fix",
  attendanceStart: "/attendance/start",
  attendanceEnd: "/attendance/end",
  attendanceCalcTime: "/attendance/calc",
  attendanceCalc: "/attendance/salarycalc",
  attendanceCalcCheck: "/attendance/calccheck",
  attendanceSalaryFix: "/attendance/salaryfix",
  attendanceMultiSalaryCalc: "/attendance/multisalarycalc",
  attendanceSalaryConfirm: "/attendance/salary-confirm",
  attendanceBulk: "/attendance/bulk",
  attendanceAssist: "/attendance/assist",
  attendanceOther: "/attendance/other",
  attendanceCheck: "/attendance/check",
  attendanceCheckEnd: "/attendance/check-end",
  shain: "/shain",
  shain1: "/daijin/shain1",
  shozoku: "/daijin/shozoku",
  shiftUpsert: "shift/upsert",
  shiftpattern: "/shiftpattern",
  shiftpatternPulldown: "/shiftpattern/pull",
  workClassification: "/workc",
  shift: "/shift",
  shiftExcel: "/shift/excel",
  shiftded: "/shift/deleteDed",
  authinfoTemporary: "/authinfo/temporary",
  codeGroup: "/codegroup",
  codeGroupPull: "/codegroup/pull",
  forCalc: "/for-calc",
  forCalcWelfare: "/for-calc/welfare",
  codeMaster: "/code-master",
  codeMasterPulldown: "/code-master/pulldown",
  shozokuQr: "/daijin/shozoku/qr",
  shainQr: "/daijin/shain1/qr",
  shozokuExp: "/shozoku-exp",
  shainExp: "/shain-exp",
  welfareAttendanceImport: "welfare/import",
  authinfo: "/authinfo",
  authinfoBulk: "/authinfo/bulk",
  dailyEvent: "/daily-event",
  shiftOrder: "/shift-order",
  shozokuFilter: "/daijin/shozoku/filter",
  shozokuFilterAssist: "/daijin/shozoku/filter/assist",
  shainFilter: "/daijin/shain1/filter",
  shainApprovalFilter: "/daijin/shain1/filterApproval",
  shainPulldown: "/daijin/shain1/pulldown",
  shiftAttendance: "/shift-attendance",
  meetingAdd: "/Attendance/meeting",
  tmpDistance: "/welfare/distance",
  relyToShain: "/rely-to-shain",
  relyToShozoku: "/rely-to-shozoku",
  attendanceCalcTimeFukushi: "/attendance/calcfukushi",
  attendanceCalcFukushi: "/attendance/salarycalcfukushi",
  attendanceMultiCalc: "/attendance/calc-multi",
  shainSorted: "/daijin/shain1/sorted",
  shiftPatternOrder: "/shiftpattern/order",
  authNomalUser: "/auth/user",
  attendanceStartSp: "/attendance/sp/start",
  attendanceEndSp: "/attendance/sp/end",
  trainingAdd: "/welfare/training",
  attendanceMultiCalcFukushi: "/attendance/calc-multi-fukushi",
  attendanceMultiSaralyCalcFukushi: "/attendance/multisalarycalc-fukushi",
  approvalList: "/approval",
  approvalStatus: "/approval/status",
  shozokuFilterForCalc: "/daijin/shozoku/filter/for-calc",
  shozokuFilterForCalcWelfare: "/daijin/shozoku/filter/for-calc/welfare",
  shiftLock: "/shift-lock",
  transportationExpenses: "/transportation-expenses",
  attendanceapproval: "attendance/approval",
  shozokuGroup: "/daijin/shozoku/group",
  shozokuDetail: "/shozoku",
  shozokuDetailPull: "/shozoku/pulldown",
  shozokuDetailPullGroup: "/shozoku/pulldown/group",
  shozokuDetailId: "/shozoku/id",
  shainKkubun: "/daijin/shain3/kkubun",
  smileLinkageShain: "/smile-linkage/shain",
  smileLinkageShozoku: "/smile-linkage/shozoku",
  smileLinkageTanka: "/smile-linkage/tanka",
  shozokuApproval: "/daijin/shozoku/approval",
  vacationrequest: "/vacationRequest",
  vacationrequestFix: "/vacationRequest/fix",
  shainExpWork: "/shain-exp-work",
  OutputCalc: "/output-calc",
  affiliation: "/affiliation-summary",
  shozokuMaster: "/shozokumaster",
  shozokuExpTanka: "/shozoku-exp-tanka",
  baseMaster: "/basemaster",
  Request: Request,
  petition: petitionPaths,
  alert: alertPaths,
  approval: approvalPaths,
  checkApproval: checkApproval,
  userMaster
} as const;

declare module "vue/types/vue" {
  interface Vue {
    Paths: typeof Paths;
  }
}

export const paths = {
  install(Vue: VueConstructor) {
    Vue.prototype.Paths = Paths;
  }
};
