import { VueConstructor } from "vue";
import QrCodeReaderDialog from "@/plugins/dialog/content/QrCodeReaderDialog.vue";
import UpdateDialog from "@/plugins/dialog/content/UpdateDialog.vue";

type DialogListType = "qr-code-reader" | "update";

declare module "vue/types/vue" {
  interface Vue {
    $dialog(type: DialogListType, title?: string): Promise<string | undefined>;
  }
}

export const dialog = {
  install(Vue: VueConstructor) {
    Vue.prototype.$dialog = (type: DialogListType, title?: string) => {
      return new Promise(resolve => {
        switch (type) {
          case "qr-code-reader":
            new QrCodeReaderDialog({
              propsData: {
                title,
                success: (result: string) => resolve(result),
                failure: () => resolve(undefined)
              }
            });
            break;
          case "update":
            new UpdateDialog({
              propsData: {
                success: () => resolve(),
                failure: () => resolve()
              }
            });
            break;
        }
      });
    };
  }
};
