import { NavigationGuardNext, Route } from "vue-router";

import Store from "@/store/index";

function isLogin(): boolean {
  return Store.state.login;
}

function getToken(): string {
  return Store.state.token;
}

export default function beforeEach(
  to: Route,
  from: Route,
  next: NavigationGuardNext
): void {
  if (!to.matched.some(page => page.meta.public))
    if (!isLogin()) next("/login");

  const path = "/office";
  if (to.path === "/") {
    if (from.path === path) return;
    next(path);
  }
  next();
}
