
import Vue, { PropType } from "vue";
import Beep from "@/assets/sound/QR.mp3";
import QrReader from "@/components/QrReader.vue";

import Vuetify, {
  VDialog,
  VCard,
  VCardText,
  VToolbar,
  VToolbarTitle,
  VIcon,
  VBtn,
  VSpacer,
  VProgressLinear
} from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
Vue.use(Vuetify);

const audio = new Audio(Beep);
const intervalMilis = 60000;
const interval = 100;

export default Vue.extend({
  name: "QrCodeReaderDialog",
  vuetify: new Vuetify(),
  components: {
    VDialog,
    VCard,
    VCardText,
    VToolbar,
    VToolbarTitle,
    VIcon,
    VBtn,
    VSpacer,
    VProgressLinear,
    QrReader
  },
  props: {
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      type: String,
      required: false,
      default: "mdi-qrcode-scan"
    },
    success: {
      type: Function as PropType<(value?: unknown) => void>,
      required: true
    },
    failure: {
      type: Function as PropType<(value?: unknown) => void>,
      required: true
    }
  },
  data() {
    return {
      isActive: false,
      intervalMilis, //待機時間
      countMilis: intervalMilis,
      intervalID: undefined as number | undefined
    };
  },
  computed: {
    countPerInterval: {
      get(): number {
        return (this.countMilis / this.intervalMilis) * 100;
      }
    },
    countTitle: {
      get(): string {
        return `${Math.floor(this.countMilis / 1000)}秒間待機します。`;
      }
    }
  },
  watch: {
    countMilis: {
      handler(newValue: number) {
        if (newValue <= 0) {
          clearInterval(this.intervalID);
          this.close();
        }
      }
    }
  },
  methods: {
    startBuffer() {
      clearInterval(this.intervalID);
      this.intervalID = setInterval(
        () => (this.countMilis -= interval),
        interval
      );
    },
    async decode(value: unknown) {
      if (typeof value === "string") {
        audio.play();
        this.success(value);
        this.destroy();
      }
    },
    close() {
      this.failure();
      this.destroy();
    },
    destroy() {
      this.isActive = false;

      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
        if (document.body.contains(this.$el)) {
          document.body.removeChild(this.$el);
        }
        this.$destroy();
      }, 200);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalID);
  },
  created() {
    //呼び出されたときにマウント
    const el = document.createElement("div");
    document.body.appendChild(el);
    this.$mount(el);

    this.isActive = true;
    this.startBuffer();
  }
});
