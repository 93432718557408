<template>
  <DialogBase
    visible
    ref="dialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template v-slot:title>
      <v-icon left dark>mdi-qrcode-scan</v-icon>
      {{ Title }}
      <v-spacer />
      <v-btn icon dark @click="$close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <!-- <v-form v-model="valid" ref="form"> -->
    <div>
      <v-progress-linear :value="(count / (interval * 1000)) * 100" />
      <QrReader v-model="data" v-if="!!count" @decode="decode" />
    </div>
    <!-- </v-form> -->
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";

import axios from "axios";

export default {
  name: "QrDialog",
  mixins: [Common, Dialog, Forms],
  components: {
    QrReader: () => import("@/components/QrReader.vue")
    // QrcodeStream
  },
  // props: { args: Object },
  data() {
    return {
      data: null,
      count: 0,
      interval: 60,
      progress: 100,
      task: null,
      audio: null
    };
  },
  computed: {
    Title() {
      return `${Math.floor(this.count / 1000)} 秒待機します。`;
    }
  },
  methods: {
    update() {
      const interval = 100;
      this.count = this.interval * 1000;

      if (!this.task)
        this.task = setInterval(() => {
          this.count -= interval;
          if (this.count < 1) {
            clearInterval(this.task);
            this.task = null;
            this.$close();
          }
        }, interval);
    },
    play() {
      this.audio.play();
    },
    decode(result) {
      this.play();
      this.$close(result);
    }
  },
  async created() {
    console.log(this.name + " created");

    const sound = require("@/assets/sound/QR.mp3") + "";

    const result = await axios.get(sound, {
      responseType: "blob",
      headers: {
        "Content-Type": "audio/mpeg"
      }
    });

    // const data = result.data;
    const url = URL.createObjectURL(result.data); //this.data);

    this.audio = new Audio(url);
  },
  mounted() {
    this.update();
  }
};
</script>

<style></style>
