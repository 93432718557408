
import Vue, { PropType } from "vue";
import Vuetify, { VOverlay, VProgressCircular } from "vuetify/lib";

Vue.use(Vuetify);

export default Vue.extend({
  name: "Loading",
  vuetify: new Vuetify(),
  components: { VOverlay, VProgressCircular },
  props: {
    queue: {
      type: Array as PropType<boolean[]>
    }
  },
  computed: {
    isActive: {
      get(): boolean {
        return this.queue.length !== 0;
      }
    }
  },
  created() {
    //呼び出されたときにマウント
    const el = document.createElement("div");
    document.body.appendChild(el);
    this.$mount(el);
  }
});
