<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    :icon="Icon"
    :header-color="HeaderColor"
    x-small
    :yes="Buttons.yes"
    :no="Buttons.no"
    :del="Buttons.del"
    :close="Buttons.close"
    @clickYes="close(true)"
    @clickNo="close(false)"
    @clickDelete="close(true)"
    @clickClose="close(false)"
  >
    <span v-for="msg in Messages" :key="msg">{{ msg }}<br /></span>
  </DialogBase>
</template>

<script>
// lang="ts"
import Dialog from "@/mixins/dialog";

const Contents = {
  confirm: {
    icon: "mdi-information",
    title: "確認",
    color: "primary",
    buttons: {
      yes: true,
      no: true
    }
  },
  info: {
    icon: "mdi-information",
    title: "情報",
    color: "info",
    buttons: {
      close: true
    }
  },
  delete: {
    icon: "mdi-delete",
    title: "情報",
    color: "error",
    buttons: {
      close: true,
      del: true
    }
  },
  warning: {
    icon: "mdi-alert",
    title: "警告",
    color: "warning",
    buttons: {
      close: true
    }
  },
  error: {
    icon: "mdi-close-circle",
    title: "エラー",
    color: "error",
    buttons: {
      close: true
    }
  }
};

export default {
  name: "ConfirmDialog",
  mixins: [Dialog],
  props: {
    title: String,
    message: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "info"
    }
  },
  computed: {
    Content() {
      return Contents[this.type];
    },
    Title() {
      if (this.title) return this.title;

      return this.Content.title;
    },
    Icon() {
      return this.Content.icon;
    },
    HeaderColor() {
      return this.Content.color;
    },
    Buttons() {
      return this.Content.buttons;
    },
    Messages() {
      try {
        return this.message.split("\n");
      } catch {
        return [];
      }
    }
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    }
  }
}; //);
</script>

<style></style>
