import TmpLogin from "@/models/TemporaryLoginResponse";

let task: number | null;

const self = {
  namespaced: true,
  state: {
    incode: "",
    name: "",
    login: false,
    code: ""
  },
  mutations: {
    login(state: any, payload: TmpLogin) {
      console.log("user/login", payload, state, self);

      state.login = true;
      state.name = payload.name;
      state.incode = payload.incode;
      state.code = payload.code;
    },
    logout(state: any) {
      console.log("user/logout");
      if (task !== null) clearTimeout(task);

      state.login = false;
      state.name = "";
      state.incode = "";
      state.code = "";
    }
  }
};
export default self;
