
import Vue, { PropType } from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import { AlertType } from "./alert";

Vue.use(Vuetify);

const items = {
  success: {
    color: "success",
    icon: "mdi-check-circle"
  },
  info: {
    color: "info",
    icon: "mdi-information-outline"
  },
  warning: {
    color: "warning",
    icon: "mdi-alert-outline"
  },
  error: {
    color: "error",
    icon: "mdi-alert-octagon-outline"
  }
};

export default Vue.extend({
  name: "AlertDialog",
  vuetify: new Vuetify(),
  components: { VSnackbar, VBtn, VIcon },
  props: {
    message: {
      type: String,
      required: true
    },
    type: {
      type: String as PropType<AlertType>,
      required: true
    },
    timeout: {
      type: Number,
      required: false,
      default: 6000
    },
    close: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  data() {
    return {
      isActive: false,
      typeList: items,
      app: null as HTMLElement | null
    };
  },
  watch: {
    isActive(newValue: boolean) {
      if (!newValue) {
        this.onClose();
      }
    }
  },
  methods: {
    onClose() {
      this.close();
      this.destroy();
    },
    destroy() {
      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
        if (document.body.contains(this.$el)) {
          document.body.removeChild(this.$el);
        }
        // if (this.app?.contains(this.$el)) {
        //   this.app.removeChild(this.$el);
        // }
        this.$destroy();
      }, 200);
    }
  },
  created() {
    const el = document.createElement("div");
    document.body.appendChild(el);
    this.$mount(el);
    // const app = document.getElementById("app");
    // if (app) {
    //   app.appendChild(el);
    //   this.$mount(el);
    //   this.app = app;
    // }

    this.isActive = true;
  }
});
