import Vue from "vue";

export default Vue.extend({
  name: "Dialog",
  components: {
    DialogBase: () => import("@/components/DialogBase.vue")
  },
  props: {
    width: String,
    resolve: Function,
    args: [Array, Object]
  },
  data() {
    return {
      visible: false,
      error: null
    };
  },
  created() {
    this.$mount();
    document.body.appendChild(this.$el);
    this.visible = true;
  },
  methods: {
    $close(result: any = undefined): void {
      if (result !== undefined && result !== null) {
        this.resolve(result);
      } else {
        this.resolve(result);
      }

      this.visible = false;
      setTimeout(() => {
        if (document.body.contains(this.$el))
          document.body.removeChild(this.$el);
        this.$destroy();
      }, 200);
    }
  }
});
