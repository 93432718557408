
import Vue, { PropType } from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default Vue.extend({
  name: "UpdateDialog",
  vuetify: new Vuetify(),
  props: {
    success: {
      type: Function as PropType<(value?: unknown) => void>,
      required: true
    },
    failure: {
      type: Function as PropType<(value?: unknown) => void>,
      required: true
    }
  },
  data() {
    return {
      icon: "mdi-information",
      title: "更新",
      message:
        "システムが更新されました。\n最新バージョンを取得するため\nページを再読み込みします。\n再度、ログインをしてください。",
      isActive: false
    };
  },
  methods: {
    close() {
      this.success();
      this.destroy();
    },
    destroy() {
      this.isActive = false;

      // 即座にdomを削除するとtransitionする前に消えてしまうので、200ms待つ
      setTimeout(() => {
        if (document.body.contains(this.$el)) {
          document.body.removeChild(this.$el);
        }
        this.$destroy();
      }, 200);
    }
  },
  created() {
    //呼び出されたときにマウント
    const el = document.createElement("div");
    document.body.appendChild(el);
    this.$mount(el);

    this.isActive = true;
  }
});
