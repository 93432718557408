import Vue from "vue";
import axios, { AxiosResponse } from "axios";
import Login from "@/models/Login";
import LoginResponse from "@/models/LoginResponse";

import Store from "@/store/index";

const BaseURL = process.env.VUE_APP_API_BASE_URL;
// const BaseURL = "https://www.hgswp01.com/aries/api";
// const BaseURL = "https://securityehime-webschedule.com/instance1/api";

function getToken() {
  return Store.state.token;
}

function getManagerToken() {
  return Store.state.mtoken;
}

function createHttpClient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken()
    }
  });
}

function createDownloadExcelHttpCLient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "application/zip"
    },
    responseType: "blob"
  });
}

function createCSVUploadHttpClient() {
  return axios.create({
    baseURL: BaseURL,
    headers: {
      authorization: getToken(),
      UserAuthorization: getManagerToken(),
      "Content-Type": "multipart/form-data"
    },
    responseType: "blob"
  });
}

function getUrl(baseUrl: string, params: string | string[]): string {
  // const param = params
  //   ? params instanceof String
  //     ? params
  //     : (params as string[]).join("/")
  //   : "";
  const param = params ? params : "";
  const url = param
    ? baseUrl + (baseUrl.match("^.*/$") ? "" : "?") + param
    : baseUrl + (baseUrl.match("^.*/$") ? "" : "/");
  return url;
}

export default Vue.extend({
  name: "Api",
  methods: {
    async $auth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr ? "1" : "0"
      };

      let path = "";
      switch (payload.selectTab) {
        case 0:
          path = this.Paths.atuhShozoku;
          break;
        case 1:
          path = this.Paths.authManager;
          break;
        default:
          path = this.Paths.authNomalUser;
          break;
      }

      return await this.$post(path, data);
    },
    async $tmpauth(payload: Login): Promise<any> {
      console.log("auth", payload);

      const data: any = {
        loginId: payload.loginId,
        password: payload.password,
        isQr: payload.isQr
      };

      return await this.$post(this.Paths.authTemporary, data);
    },
    async $verify(payload: Login): Promise<any> {
      console.log("auth", payload);

      const token = getToken();

      if (token) {
        Store.state.name = "ダミー";
        Store.state.login = true;
      }

      return !!token;
    },
    /**
     * Get Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    // async $get<T>(
    //   path: string,
    //   params: string | string[],
    //   query: any
    // ): Promise<T> {
    //   const client = createHttpClient();

    //   const url = getUrl(path, params);

    //   const result = await client.get<T>(url, { params: query });

    //   /** @ts-ignore */
    //   const error = result.data.error;
    //   if (error) {
    //     console.error(error);
    //     throw new Error(error.errorMessage);
    //   }

    //   console.log(result);
    //   return result.data;
    // },

    async $downloadexcel(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createDownloadExcelHttpCLient();

      const url = getUrl(path, params);

      const result = await client.get(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * POST Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    // async $post<T = any>(
    //   path: string,
    //   data: any,
    //   params: string | string[] = ""
    // ): Promise<T> {
    //   const client = createHttpClient();

    //   const url = getUrl(path, params);

    //   const result = await client.post<T>(url, data);

    //   const error = result.data as any;
    //   if (error.error) {
    //     console.error(error.error);
    //     throw new Error(error.error.errorMessage);
    //   }

    //   console.log(result);
    //   return result.data;
    // },
    async $csvupload(path: string, file: any): Promise<any> {
      const client = createCSVUploadHttpClient();

      const data = new FormData();
      data.append("csvfile", file);

      const result = await client.post(path, data);

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },
    /**
     * PUT Method
     * @param {String} path
     * @param {Object} data
     * @param {String|String[]} params
     */
    async $put(
      path: string,
      data: any,
      params: string | string[]
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("PUT: " + url + " &" + JSON.stringify(data));

      const result = await client.put(url, data);

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    },

    /**
     * DELETE Method
     * @param {String} path
     * @param {String|String[]} params
     * @param {Object} query
     */
    async $del(
      path: string,
      params: string | string[],
      query: any
    ): Promise<any> {
      const client = createHttpClient();

      const url = getUrl(path, params);

      console.log("DELETE: " + url + "?" + JSON.stringify(query));

      const result = await client.delete(url, { params: query });

      const error = result.data.error;
      if (error) {
        console.error(error);
        throw new Error(error.errorMessage);
      }

      console.log(result);
      return result.data;
    }
  }
});
